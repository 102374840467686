import { CartSkuApiDto, OfferCampaignApiDto } from '@b2x/storefront-api-js-client/src';
import classNames from 'classnames';

import { BlankButton } from './BlankButton';
import { Button } from './Button';
import { CartSku } from './CartSku';
import { Col } from './Col';
import { EqualHeight } from './EqualHeight';
import { EqualHeightElement } from './EqualHeightElement';
import { Div, Span } from './HTMLElement';
import { t } from './i18n/i18n';
import { Image } from './Image';
import { Loading } from './Loading';
import { Modal, ModalBody, ModalHeader } from './Modal';
import { Portal } from './Portal';
import { Row } from './Row';
import { useCartCampaignSingleOfferBuyXGetYFlattenedSkus } from './useCartCampaignSingleOfferBuyXGetYFlattenedSkus';
import { useModalByRef } from './useModal';
import { uiClassName } from './util';

export interface CartCampaignSingleOfferBuyXGetYFlattenedSkusProps {
  campaign: OfferCampaignApiDto;
  campaignsCartSkus: Partial<Record<string, Array<CartSkuApiDto>>> | undefined;
}

export const CartCampaignSingleOfferBuyXGetYFlattenedSkus = ({
  campaign,
  campaignsCartSkus,
}: CartCampaignSingleOfferBuyXGetYFlattenedSkusProps) => {
  const {
    addToCartButtonEnabled,
    campaignCartSkus,
    handleAddToCartButtonClick,
    handlePickerSkuButtonClick,
    offer,
    pickerSkus,
    selectedSkusIds,
  } = useCartCampaignSingleOfferBuyXGetYFlattenedSkus({
    campaign,
    campaignsCartSkus,
  });
  const [pickerModalRef, showPickerModal, closePickerModal] = useModalByRef('modal');

  return (
    <>
      <Div className="cart-campaign-single-offer-buyxgety-flattened-skus" padding={4}>
        <Div display="flex" justifyContent="spaceBetween">
          <BlankButton onClick={showPickerModal}>
            {/* {appConfig.icons?.cart && (
              <Span className="icon" paddingEnd={2}>
                <Icon name={appConfig.icons.cart} />
              </Span>
            )} */}
            <Span className="name">{offer.name}</Span>
          </BlankButton>
          {campaignCartSkus && campaignCartSkus.length > 0 && (
            <BlankButton onClick={showPickerModal}>
              {/* {appConfig.icons?.cart && (
                <Span className="icon" paddingEnd={2}>
                  <Icon name={appConfig.icons.cart} />
                </Span>
              )} */}
              <Span className="edit">{t('misc.edit')}</Span>
            </BlankButton>
          )}
        </Div>
        {campaignCartSkus && campaignCartSkus.length > 0 && (
          <Div background="white">
            {campaignCartSkus.map((campaignCartSku, index) => (
              <Div key={campaignCartSku.id} marginTop={4} paddingX={3}>
                <CartSku
                  cartSku={campaignCartSku}
                  index={index}
                  temporaryPropToDisableProductPageLink
                  temporaryPropToDisableWishlistButton
                />
              </Div>
            ))}
          </Div>
        )}
      </Div>
      <Portal>
        <EqualHeight>
          <Modal
            className="cart-campaign-single-offer-buyxgety-flattened-skus-picker"
            innerRef={pickerModalRef}
            size="extra-large"
          >
            <ModalHeader title="" />
            <ModalBody
              className={uiClassName({
                bs5: 'pt-0 text-center',
              })}
            >
              <Div className="title" marginBottom={1}>
                {offer.name}
              </Div>
              <Div className="subtitle" marginBottom={3}>
                {t('misc.productsPicker.progress', { count: selectedSkusIds.length, total: offer.yQuantity })}
              </Div>
              <Div className="skus" marginBottom={5}>
                {pickerSkus ? (
                  <Row cols={{ lg: 4, xs: 2 }} gapX={3} gapY={5}>
                    {pickerSkus.map((pickerSku, index) => (
                      <Col key={pickerSku.id}>
                        <Div className={classNames('sku', { selected: pickerSku.selected })}>
                          <Div marginBottom={2}>
                            <EqualHeightElement colIndex={index} name="image">
                              <Image {...pickerSku.image} fluid format={800} />
                            </EqualHeightElement>
                          </Div>
                          <Div className="name" marginBottom={2}>
                            <EqualHeightElement colIndex={index} name="name">
                              {pickerSku.product.name && <div>{pickerSku.product.name}</div>}
                              {pickerSku.name && <div>{pickerSku.name}</div>}
                            </EqualHeightElement>
                          </Div>
                          <Div display="grid">
                            <Button
                              className="select"
                              disabled={!pickerSku.buttonEnabled}
                              label={
                                pickerSku.selected ? t('misc.productsPicker.selected') : t('misc.productsPicker.select')
                              }
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => {
                                handlePickerSkuButtonClick(pickerSku.id);
                              }}
                              variant="plain"
                            />
                          </Div>
                        </Div>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Div textAlign="center">
                    <Loading color="black" />
                  </Div>
                )}
              </Div>
              <Div className="add-to-cart-button" marginBottom={3}>
                <Button
                  disabled={!addToCartButtonEnabled}
                  label={t('misc.addToCart')}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => {
                    handleAddToCartButtonClick().then(() => {
                      closePickerModal();
                    });
                  }}
                />
              </Div>
            </ModalBody>
          </Modal>
        </EqualHeight>
      </Portal>
    </>
  );
};
